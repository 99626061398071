<!--
* @Author: DY
* @Date: 2021年1月12日11:33:14
* @Description: '选择市场信息价'
-->
<template>
    <div class="selectMarketInformationPrice">
        <div class="currencyTable" v-loading="loading">
            <div class="table-header">
                <div class="el_left queryCriteria">
                    <div class="queryCriteriaList selectRegion" v-if="isSelectRegion">
                        <h4>区域选择： </h4>
                        <el-radio-group v-model="selectRegion" @change="selectArea">
                            <template v-for="(item, index) in regionData">
                                <el-radio :key="index" :label="item.info_price_regional_number">
                                    {{ item.region_name }}
                                </el-radio>
                            </template>
                        </el-radio-group>
                    </div>
                    <div class="queryCriteriaList period" v-if="isSelectPeriod">
                        <h4>期次选择： </h4>
                        <el-select
                            placeholder="请选择"
                            v-model="basePricePeriodNumber"
                            @change="selectPeriod"
                        >
                            <template v-for="(item,index) in periodData">
                                <el-option
                                    :key="`period_${index}`"
                                    :label="item.current_period"
                                    :value="item.info_price_period_number"
                                >
                                </el-option>
                            </template>
                        </el-select>
                    </div>
                </div>

                <div class="el_right">
                    <slot :data="tableSelectData">
                        <div class="el_left conditionQuery" v-if="isConditionQuery">
                            <el-input placeholder="请输入搜索内容..." v-model="generalQueryText" class="input-with-select">
                                <el-button slot="append" icon="el-icon-search" @click="generalQuery"></el-button>
                            </el-input>
                        </div>
                    </slot>
                    <div class="el_left setUp">
                        <el-button v-popover:popover>
                            <i class="iconfont iconshezhi"></i>
                        </el-button>
                        <el-popover
                            ref="popover"
                            placement="bottom-start"
                            title="自定义列表项"
                            width="540"
                            trigger="click"
                            v-model="isPopover"
                        >
                            <div class="tablePopover">
                                <div class="popoverContent">
                                    <el-checkbox-group v-model="selectListItem">
                                        <template v-for="(item, index) in listDisplayItems">
                                            <el-checkbox :key="index" :label="item.field">
                                                {{ item.title }}
                                            </el-checkbox>
                                        </template>
                                    </el-checkbox-group>
                                </div>
                                <div class="el_right">
                                    <el-button size="mini" @click="isPopover = false">
                                        取消
                                    </el-button>
                                    <el-button size="mini" type="primary" @click="confirmListItem">
                                        确定
                                    </el-button>
                                </div>
                            </div>
                        </el-popover>
                    </div>
                </div>
            </div>
            <el-table
                border
                stripe
                :data="tableList"
                ref="selectMarketInformationPriceTable"
                @selection-change="handleSelectionChange"
                @row-click="selateTable"
                :highlight-current-row="true"
            >
                <el-table-column
                    key="select"
                    type="selection"
                    width="55"
                    v-if="showCheckBox"
                >
                </el-table-column>
                <el-table-column
                    label="选择"
                    width="55"
                    v-if="showRadio"
                >
                    <template slot-scope="scope">
                        <el-radio
                            v-model="radio"
                            :label="scope.row"
                        >
                            <i></i>
                        </el-radio>
                    </template>
                </el-table-column>
                <el-table-column
                    label="序号"
                    type="index"
                    width="70"
                    :index="indexMethod"
                    v-if="isShowNo"
                >
                </el-table-column>
                <el-table-column
                    prop="info_price_number"
                    label="编码"
                >
                </el-table-column>
                <el-table-column
                    prop="info_price_name"
                    label="产品名称"
                >
                </el-table-column>
                <el-table-column label="规格型号">
                    <el-table-column
                        prop="specification_mode_name"
                        label="名称"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="product_mark_num"
                        label="产品标号"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="additional_model"
                        label="附加型号"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="pouring_name"
                        label="浇筑方式"
                    >
                    </el-table-column>
                </el-table-column>
                <el-table-column label="单位">
                    <template>
                        m³
                    </template>
                </el-table-column>
                <el-table-column
                    prop="included_tax_unit_price"
                    label="含税单价(元)"
                >
                </el-table-column>
                <el-table-column
                    prop="tax_rate"
                    label="税率"
                >
                </el-table-column>
                <el-table-column
                    prop="after_tax_unit_price"
                    label="除税单价(元)"
                >
                </el-table-column>
            </el-table>
            <div class="table-page">
                <div class="el_right">
                    <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="page"
                        :page-sizes="[20,40,60,80,100]"
                        :page-size="limit"
                        :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <footer>
            <el-button type="primary" @click="confirmSelection">
                确认
            </el-button>
            <el-button @click="cancel">
                取消
            </el-button>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'select-market-information-price',
    components: {},
    props: {
        extr: {
            type: [Array, Object, String, Number],
        },
    },
    data() {
        return {
            loading: false,
            // 查询条件
            generalQueryText: '',
            // 是否显示普通查询
            isConditionQuery: true,
            // 自定义列表项数据
            listDisplayItems: [],
            // 已选中显示项
            selectListItem: [],
            // 列表项选择弹出层状态
            isPopover: false,
            // 列表头部数据
            tableHeader: [],
            // 列表数据
            tableList: [],
            // 数据总条数
            total: 0,
            // 当前页码
            page: 1,
            // 每页显示条目个数
            limit: 20,
            // 是否显示复选框
            showCheckBox: true,
            // 是否显示单选框
            showRadio: false,
            // 是否显示序号列
            isShowNo: false,
            // 单选数据
            radio: {},
            // 列表选择数据集合
            tableSelectData: [],
            // 是否显示选择区域
            isSelectRegion: false,
            // 区域数据
            regionData: [],
            // 选择区域
            selectRegion: '',
            // 是否回显已选数据（默认否）
            isEcho: false,
            // 回显数据
            echoData: [],
            // 回显过滤字段
            echoFilterFields: '',

            // 期次下拉数据
            periodData: [],
            // 是否显示选择期次
            isSelectPeriod: false,
            basePricePeriodNumber: '',
            basePricePeriodName: '',
        };
    },
    watch: {},
    computed: {},
    created() {
        if (this.extr) {
            this.selectRegion = this.extr.requestParameter.info_price_regional_number;
            if (this.extr.showRadio) {
                this.showRadio = true;
                this.showCheckBox = false;
            }
            if (this.extr.isEcho) {
                this.isEcho = true;
                this.echoData = this.extr.echoData;
                this.echoFilterFields = this.extr.echoFilterFields;
            }
            // 是否跨区域
            if (this.extr.is_trans_regional) {
                this.isSelectRegion = true;
                // 获取区域数据
                this.getRegionData();
            }
            // 是否需要获取期次 = 按基准产品单价浮动 【ly 是否需要获取期次 = 按基准产品单价浮动】
            if (!this.extr.isPeriodRequired) {
                this.isSelectPeriod = true;
                this.getPeriodData(this.selectRegion);
            } else if (this.extr.requestUrl) {
                // 获取列表数据
                this.getTableData();
            }
        }
    },
    mounted() {},
    methods: {
        // 获取区域数据
        getRegionData() {
            this.$axios
                .get('/interfaceApi/sale/info_price/get_regional_list')
                .then(res => {
                    if (res) {
                        this.regionData = res;
                    } else {
                        this.regionData = [];
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 选择区域
        selectArea() {
            this.page = 1;
            this.total = 0;
            this.tableList = [];
            // 获取列表数据
            this.getTableData();
        },

        // 获取期次数据
        getPeriodData(areaId) {
            this.$axios
                .get(`/interfaceApi/sale/info_price/get_period_list?regionalNumber=${areaId}`)
                .then(res => {
                    if ((res || []).length > 0) {
                        this.periodData = res;
                        this.basePricePeriodNumber = res[0].info_price_period_number;
                        this.basePricePeriodName = res[0].current_period;
                        this.extr.requestParameter.info_price_period_number = this.basePricePeriodNumber;
                        // 获取列表数据
                        this.getTableData();
                    } else {
                        this.periodData = [];
                        this.$message({
                            message: '当前区域没有期次数据！',
                            type: 'warning',
                        });
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 选择期次
        selectPeriod() {
            this.extr.requestParameter.info_price_period_number = this.basePricePeriodNumber;
            const row = this.periodData.filter(item => item.info_price_period_number === this.basePricePeriodNumber)[0];
            this.basePricePeriodName = row.current_period;
            // 获取列表数据
            this.getTableData();
        },

        // 计算列表序号
        indexMethod(index) {
            return (this.page - 1) * this.limit + index + 1;
        },
        // 点击行选中
        selateTable(row) {
            this.tableSelectData = [];
            if (this.showCheckBox) {
                this.$refs.selectMarketInformationPriceTable.toggleRowSelection(row);
                this.tableSelectData = this.$refs.selectMarketInformationPriceTable.selection;
            }
            if (this.showRadio) {
                this.radio = row;
                this.tableSelectData = row;
            }
        },
        // 多选选中
        handleSelectionChange(val) {
            this.tableSelectData = val;
        },
        // 确认选择列表显示项
        confirmListItem() {
            const newColumns = [];
            this.listDisplayItems.forEach(k => {
                this.selectListItem.forEach(item => {
                    if (item === k.field) {
                        newColumns.push(k);
                    }
                });
            });
            this.tableHeader = newColumns;
            this.isPopover = false;
        },
        // 列表上link点击方法
        handleLinkClick(link, row) {
            this.$emit(link.method, row);
        },
        // 列表上按钮点击方法
        handleBtnClick(btn, row) {
            this.$emit(btn.method, row);
        },
        // 设置每页条数
        handleSizeChange(val) {
            this.limit = val;
            this.getTableData();
        },
        // 切换页码
        handleCurrentChange(val) {
            this.page = val;
            this.getTableData();
        },
        // 查询
        generalQuery() {
            this.getTableData();
        },
        // 获取列表数据
        getTableData() {
            const requestUrl = `/interfaceApi/sale/${this.extr.requestUrl}`;
            const requestParameters = {
                pageindex: this.page,
                pagesize: this.limit,
            };
            const requestData = Object.assign(this.extr.requestParameter, requestParameters);
            requestData.info_price_regional_number = this.selectRegion;
            if (this.generalQueryText !== '') {
                requestParameters.search_value = this.generalQueryText;
            }
            this.$axios
                .post(requestUrl, requestData)
                .then(res => {
                    this.$set(this, 'tableList', res.rows);
                    // 数据总条数
                    this.total = res.total;
                    if (this.isEcho) {
                        this.processingEchoData();
                    }
                    // 强制刷新
                    this.$forceUpdate();
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 处理回显数据
        processingEchoData() {
            this.$nextTick(() => {
                if (this.echoData.length > 0) {
                    this.echoData.forEach(item => {
                        const row = this.tableList.filter(k => item[this.echoFilterFields] === k[this.echoFilterFields])[0];
                        if (this.showRadio) {
                            this.radio = row;
                            this.tableSelectData = row;
                        } else {
                            this.$refs.selectMarketInformationPriceTable.toggleRowSelection(row);
                            this.tableSelectData = this.$refs.selectMarketInformationPriceTable.selection;
                        }
                    });
                }
            });
        },
        // 确认选择
        confirmSelection() {
            if (this.tableSelectData.length < 1) {
                this.$message({
                    message: `请先选择${this.extr.tips}！`,
                    type: 'warning',
                });
            } else {
                // 不按基准期次价格浮动调整，回传期次数据
                let periodData = {};
                if (this.isSelectPeriod) {
                    periodData = {
                        basePricePeriodNumber: this.basePricePeriodNumber,
                        basePricePeriodName: this.basePricePeriodName,
                    };
                }
                this.extr.sureCallBack(this.tableSelectData, periodData);
                this.$parent.hide();
            }
        },
        // 取消
        cancel() {
            this.$parent.hide();
        },
    },
};
</script>

<style lang="stylus">
    .selectMarketInformationPrice
        height 100%
        .currencyTable
            height calc(100% - 0.8rem)
            padding 0.14rem
            background #fff
            border none
            .table-header
                height 0.51rem
                .queryCriteria
                    display flex
                .queryCriteriaList
                    display flex
                    line-height 0.36rem
                    h4
                        font-size 0.14rem
                    .el-radio
                        line-height 0.36rem
                .btns
                    float left
                    .el-button
                        color #fff
                        background #2978ff
                        padding 0 0.2rem
                        border none
                        line-height 0.36rem
                .conditionQuery
                    width 3.6rem
                    .el-input-group__append
                        background #1577d2
                        border 1px solid #1577d2
                        .el-button
                            color #fff
                .setUp
                    .el-button
                        width 0.36rem
                        height 0.36rem
                        padding 0
                        color #fff
                        font-size 0.16rem
                        background #1577d2
                        margin-left 0.1rem
            .el-table
                height calc(100% - 0.95rem)
                .el-table__body-wrapper
                    height calc(100% - 72px)
                    overflow-y auto
        footer
            text-align center
            height 0.6rem
            .el-button
                width 2.4rem
                font-size 0.16rem
                line-height 0.48rem
                margin 0 0.2rem
                padding 0
</style>
